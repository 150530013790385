import React, { Component } from "react";
import { connect } from "react-redux";
import MobileMenu from "../MobileMenu";
import { removeFromCart } from "../../store/actions/action";
import { NavLink } from "react-router-dom";
import HeaderTopbar from "../HeaderTopbar";

class Header extends Component {
  state = {
    isSearchShow: false,
    isCartShow: false,
  };

  searchHandler = () => {
    this.setState({
      isSearchShow: !this.state.isSearchShow,
    });
  };
  cartHandler = () => {
    this.setState({
      isCartShow: !this.state.isCartShow,
    });
  };

  render() {
    const ClickHandler = () => {
      window.scrollTo(10, 0);
    };

    return (
      <header id="header" className={this.props.topbarBlock}>
        <HeaderTopbar />
        <div className={`wpo-site-header ${this.props.hclass}`}>
          <nav className="navigation navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                  <div className="mobail-menu">
                    <MobileMenu />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-6">
                  <div className="navbar-header">
                    <NavLink
                      onClick={ClickHandler}
                      className="navbar-brand logo"
                      to="/home"
                    >
                      Appy
                      <span>
                        {/*<i className="fa fa-heart" aria-hidden="true"></i>*/}{" "}
                        &#10084;{" "}
                      </span>
                      Amanda
                    </NavLink>
                  </div>
                </div>
                <div className="col-lg-6 col-md-1 col-1">
                  <div
                    id="navbar"
                    className="collapse navbar-collapse navigation-holder"
                  >
                    <button className="menu-close">
                      <i className="ti-close"></i>
                    </button>
                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                      <li>
                        <NavLink activeClass="active" to="/home">
                          Home
                        </NavLink>
                      </li>
                      {/*
                        <li>
                        <NavLink activeClass="active" to="/gallery">
                          Gallery
                        </NavLink>
                      </li>
                         */}
                      <li>
                        <NavLink to={`/rsvp`} activeClass="active">
                          RSVP
                        </NavLink>
                      </li>
                      <li>
                        <NavLink activeClass="active" to="/schedule">
                          Schedule
                        </NavLink>
                      </li>
                      <li>
                        <NavLink activeClass="active" to="/faq">
                          FAQ
                        </NavLink>
                      </li>
                      {/*
                        <li>
                        <NavLink activeClass="active" to="/contact">
                          Contact
                        </NavLink>
                      </li>
                        */}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-2 col-2"></div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
  };
};

export default connect(mapStateToProps, { removeFromCart })(Header);
