import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Homepage from "../HomePage";
import SeviceSinglePage from "../SeviceSinglePage/index.js";
import RSVPPage from "../RSVPPage";
//import ContactPage from "../ContactPage";
import ErrorPage from "../ErrorPage";
import AccomodationPage from "../AccomodationPage";

const AllRoute = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="/home" element={<Homepage />} />
          <Route path="/rsvp" element={<RSVPPage />} />
          <Route path="/schedule" element={<AccomodationPage />} />
          <Route path="/faq" element={<SeviceSinglePage />} />
          <Route element={<ErrorPage />} />
        </Routes>
      </Router>
    </div>
  );
};

export default AllRoute;
