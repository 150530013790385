import React from "react";
import PageTitle from "../../components/pagetitle";
import LocationMap from "../Modal";

const Events = [
  {
    title: "Welcome Ceremony",
    li1: "Monday, 6 Feb, 2023 9:00 ",
    li2: "Raipur, India",
  },
  {
    title: "Haldi Ceremony",
    li1: "Monday, 6 Feb, 2023 10:00 ",
    li2: "Raipur, India",
  },
  {
    title: "Mehendi Ceremony",
    li1: "Monday, 6 Feb, 2023 12:00 ",
    li2: "Raipur, India",
  },
  {
    title: "Sangeet Ceremony",
    li1: "Monday, 6 Feb, 2023 19:00",
    li2: "Raipur, India",
  },
  {
    title: "WEDDING CEREMONY",
    li1: "Tuesday, 7 Feb, 2023 12:00 ",
    li2: "Raipur, India",
  },
  {
    title: "Wedding Reception",
    li1: "Tuesday, 7 Feb, 2023 19:00 ",
    li2: "Raipur, India",
  },
];

const EventSection = (props) => {
  return (
    <>
      <PageTitle pageTitle={"Schedule"} pagesub={"Schedule"} />
      <section
        className={`wpo-event-section section-padding ${props.eClass}`}
        id="events"
      >
        <div className="container">
          <div className="wpo-event-wrap">
            <div className="row">
              {Events.map((event, eitem) => (
                <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                  <div className="wpo-event-item">
                    <div className="wpo-event-img">
                      <img src={event.Simg} alt="" />
                    </div>
                    <div className="wpo-event-text">
                      <h2>{event.title}</h2>
                      <ul>
                        <li>{event.li1}</li>
                        <li>{event.li2}</li>
                        <li>
                          <LocationMap />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EventSection;
