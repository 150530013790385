import React, { Component } from "react";
import SectionTitle from "../../components/SectionTitle";

import vec1 from "../../images/contact/1.png";
import vec2 from "../../images/contact/2.png";

class RSVP extends Component {
  render() {
    return (
      <section className={`wpo-contact-section ${this.props.pt}`} id="RSVP">
        <div className="container">
          <div className="wpo-contact-section-wrapper">
            <div className="wpo-contact-form-area">
              <SectionTitle MainTitle={"Are you attending?"} />
              <div>
                <iframe
                  className="form"
                  title="signup form"
                  src="https://docs.google.com/forms/d/e/1FAIpQLSeVNPNrnzTyW9vjUtILrc4NXl9Tuwp6SEQAw6SvWMMud4kVoA/viewform?embedded=true"
                  width="100%"
                  height="1200"
                  frameborder="0"
                  marginheight="0"
                  marginwidth="0"
                >
                  Loading…
                </iframe>
              </div>

              <div className="border-style"></div>
            </div>
            <div className="vector-1">
              <img src={vec1} alt="" />
            </div>
            <div className="vector-2">
              <img src={vec2} alt="" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default RSVP;
