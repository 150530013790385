import React, { Component } from "react";
import CountDown from "reactjs-countdown";

class TimeCountDown extends Component {
  render() {
    return (
      <div>
        <CountDown deadline="Feb 6, 2023" />
      </div>
    );
  }
}

export default TimeCountDown;
