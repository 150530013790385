import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FAQ from "../../api/faq";

const Benefits = (props) => {
  return (
    <div className="wpo-faq-section">
      <div className="row">
        <div className="col-lg-12 col-12">
          <div
            className="wpo-benefits-item"
            style={{ maxWidth: "600px", margin: "0 auto" }}
          >
            {FAQ.map((item) => (
              <Accordion key={item.id} style={{ margin: "0.9rem" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography fontWeight="bold" style={{ color: "black" }}>
                    {item.que}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{item.ans}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
