import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import EventSection from "../../components/EventSection";

const AccomodationPage = () => {
  return (
    <Fragment>
      <Navbar />
      <EventSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AccomodationPage;
