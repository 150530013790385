import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./style.css";

export default class MobileMenu extends Component {
  state = {
    isMenuShow: false,
    isOpen: 0,
  };

  menuHandler = () => {
    this.setState({
      isMenuShow: !this.state.isMenuShow,
    });
  };

  setIsOpen = (id) => () => {
    this.setState({
      isOpen: id === this.state.isOpen ? 0 : id,
    });
  };

  render() {
    const { isMenuShow, isOpen } = this.state;
    console.log(isOpen);
    return (
      <div>
        <div className={`mobileMenu ${isMenuShow ? "show" : ""}`}>
          <div className="menu-close">
            <div className="clox" onClick={this.menuHandler}>
              <i className="ti-close"></i>
            </div>
          </div>

          <ul className="responsivemenu">
            <li>
              <NavLink activeClass="active" to="/home">
                Home
              </NavLink>
            </li>
            {/*
          <li>
          <NavLink activeClass="active" to="/gallery">
            Gallery
          </NavLink>
        </li>
           */}
            <li>
              <NavLink to={`/rsvp`} activeClass="active">
                RSVP
              </NavLink>
            </li>
            <li>
              <NavLink activeClass="active" to="/schedule">
                Schedule
              </NavLink>
            </li>
            <li>
              <NavLink activeClass="active" to="/faq">
                FAQ
              </NavLink>
            </li>
            {/*
          <li>
          <NavLink activeClass="active" to="/contact">
            Contact
          </NavLink>
        </li>
          */}
          </ul>
        </div>

        <div className="showmenu" onClick={this.menuHandler}>
          <button type="button" className="navbar-toggler open-btn">
            <span className="icon-bar first-angle"></span>
            <span className="icon-bar middle-angle"></span>
            <span className="icon-bar last-angle"></span>
          </button>
        </div>
      </div>
    );
  }
}
