const FAQ = [
  {
    id: 1,
    que: "When and where is the wedding?",
    ans: "The wedding will take place on 6th and 7th of february 2023 in Raipur, India. For more information about the two days please check the wedding schedule",
    topics: [],
  },
  {
    id: 2,
    que: "How to reach Raipur, India?",
    ans: "If you are traveling from Sweden - You can take a flight to New Delhi or Mumbai. From these two cities, there are direct flights to Raipur's Airport.",
    topics: ["travel"],
  },
  {
    id: 11,
    que: "What flight should I book?",
    ans: "It's up to you when and how you want to reach India. We will, when the wedding date getting closer, recommend a fligt to book.",
    topics: ["travel"],
  },
  {
    id: 3,
    que: "What about the covidsituation and what are the covid requirements for traveling to India?",
    ans: "As we all know after this last two years, it's impossible for anyone to say how the situation will look like in February next year. We will try to stay updated and update you guys on the way, as we getting closer to the wedding. At the moment, Travellers traveling to India need to be fully vaccinated and must have a negative PCR test taken in last 72 hours. Every airlines have different rules so please check with your airlines as well. Due to the covid situation we ask you to stay updated and be prepared for requirments to change. This is also why we recommend you to book a rebookable flight ticket",
    topic: ["covid"],
  },
  {
    id: 4,
    que: "How to apply for Indian visa?",
    ans: `If you are an European citizen, you can apply for e visa online free of charge. You can only apply for the visa 3 months before your travel date. For information visit this link : https://www.indianvisaonline.gov.in/`,
    topic: ["visa"],
  },
  {
    id: 5,
    que: "How many days do i need in India?",
    ans: "We recommend atleast 7 days assuming that you will land in New Delhi or Mumbai, Have a one night of rest and take a flight next day to Raipur. We also recommend to be in Raipur atleast a day before the wedding. The wedding will be of 2 days and then you can fly back to New Delhi or Mumbai, have a 1 night rest there before flying back to Sweden or your respective destination.",
    topic: ["travel"],
  },
  {
    id: 6,
    que: "How much the trip will cost?",
    ans: "It totally depends on you. Flights to India can approximately cost between 5000 to 7000 for a roundtrip if you book well in advance. Due to covid and less flights to India, ticket prices can change dramatically. For flight from New Delhi/Mumbai to Raipur, it can cost somewhere around 1000-1500 kroner for a roundtrip. About the hotel, you can find hotels for 100 kroner/night to 10000 kroner/night, it totally depends on your preferences. Food, taxis and other costs are really cheap in India.",
    topic: ["Travel"],
  },
  {
    id: 7,
    que: "Where to stay in Raipur?",
    ans: "As we getting closer to the wedding we will give you our recommendations where to stay. It's totally up to you where you want to stay but we will recommend a hotel close to the venue so it will be easy for you to attend the wedding.",
    topic: ["india"],
  },
  {
    id: 12,
    que: "An Indian wedding, what to expect?",
    ans: "The Indian wedding will be very different from a Swedish wedding. Imagine colors, music, alot of people and alot of fun. The normal indian wedding last for 3-5 days but we have decided to make the wedding shorter. During the two days the wedding take place, different cermonies and gatherings will happen and all of you are welcome to join. For more information please see the wedding schedule. ",
    topics: ["India"],
  },
  {
    id: 8,
    que: "What to wear for the wedding?",
    ans: "There is no dress code for the wedding - Indian weddings are colorful so please don't hesitate to wear colorful clothes. In India most people wears traditional clothes for the weddings, but if you feel more comfortable wearing a western style that's totally fine! Just remember that it's two days with different events so more than one outfit is needed (specially for the ladies). If you want to wear tradional indian clothes then please contact us.",
    topic: ["wedding"],
  },
  {
    id: 9,
    que: "What is the weather in India during february?",
    ans: "In february avg tempreture in New Delhi is around 15-18c and in Raipur its between 20-25c. Though it might sound warm you can still feel a bit cold, specially during evenings, so we recommed you to pack atleast a thin jacket or a sweater.",
    topic: [],
  },
  {
    id: 10,
    que: "What about gifts?",
    ans: "You are the gift. It's more than enough that you are willing to travel to India and celebrate together with us.",
    topic: ["wedding"],
  },
];

export default FAQ;
