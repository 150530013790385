import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/pagetitle";
import Scrollbar from "../../components/scrollbar";
import Benefits from "./benefits";
import Footer from "../../components/footer";

const SeviceSinglePage = (props) => {
  return (
    <Fragment>
      <Navbar />
      <PageTitle pageTitle={"Frequently asked question"} pagesub={"FAQ"} />

      <div className="container">
        <Benefits />
      </div>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default SeviceSinglePage;
