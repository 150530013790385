import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
//import PageTitle from "../../components/pagetitle";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import RSVP from "../../components/RSVP";

const RSVPPage = () => {
  return (
    <Fragment>
      <Navbar />
      {/*
      <PageTitle pageTitle={"RSVP"} pagesub={"RSVP"} />
      */}
      <RSVP pt={"pt-120"} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default RSVPPage;
